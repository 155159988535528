// api/axios/index.ts
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { BASE_URL } from '../../firebase_config/firebase_config'

let authToken: string | null = null
let resolveAuthTokenPromise: (token: string | null) => void
// Promise that resolves when the auth token is first set (or confirmed null)
let authTokenPromise = new Promise<string | null>((resolve) => {
    resolveAuthTokenPromise = resolve
})
let isTokenInitiallySet = false

/**
 * Sets the authentication token for Axios requests.
 * This should be called from your authentication state listener (e.g., onAuthStateChanged).
 * @param token The Firebase ID token, or null if logged out.
 */
export const setAuthToken = (token: string | null) => {
    // console.log(
    //     '[Axios Interceptor] setAuthToken called. Token:',
    //     token ? 'present' : 'null',
    //     'Initial set:',
    //     !isTokenInitiallySet
    // )
    authToken = token
    // If this is the first time the token state is being determined (even if it's null),
    // resolve the promise to unblock any queued requests.
    if (!isTokenInitiallySet) {
        isTokenInitiallySet = true
        resolveAuthTokenPromise(token)
    }
    // Subsequent calls just update the `authToken` variable, which the interceptor reads.
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${BASE_URL}/api`,
})

axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
        let currentToken = authToken

        // If the token hasn't been set initially, wait for the promise to resolve.
        // This handles requests made *before* the auth state listener fires for the first time.
        if (!isTokenInitiallySet) {
            console.log('[Axios Interceptor] Initial token not set, awaiting promise for:', config.url)
            currentToken = await authTokenPromise
            console.log(
                '[Axios Interceptor] Initial token promise resolved for:',
                config.url,
                'Token:',
                currentToken ? 'present' : 'null'
            )
        }

        // Attach the token if available
        if (currentToken) {
            config.headers.Authorization = `Bearer ${currentToken}`
            // console.log('[Axios Interceptor] Attaching token to request for:', config.url); // Optional: for debugging
        } else {
            // console.log('[Axios Interceptor] No token available for request:', config.url); // Optional: for debugging
            // Ensure no old Authorization header lingers if the user logs out
            delete config.headers.Authorization
        }
        return config
    },
    (error) => {
        // Do something with request error
        console.error('[Axios Interceptor] Request error:', error)
        return Promise.reject(error)
    }
)

export default axiosInstance
