export const constructProductRoute = (brandId: string, productId?: string) => {
    if (productId) {
        return `/brands/${brandId}/products/${productId}`
    } else {
        return `/brands/${brandId}/products`
    }
}

export const constructBrandRoute = (brandId?: string) => {
    if (brandId) {
        return `/brands/${brandId}`
    }
    return `/brands`
}

export const ideaRoute = (teamId: string, ideaId?: string) => {
    if (ideaId) {
        return `/teams/${teamId}/ideas/${ideaId}`
    } else {
        return `/teams/${teamId}/ideas`
    }
}

export const bdocRoute = (brandId: string, docId?: string) => {
    if (docId) {
        return `/brands/${brandId}/brainstormDocs/${docId}`
    } else {
        return `/brands/${brandId}/brainstormDocs`
    }
}

export const bdocCommentRoute = (brandId: string, docId: string, commentId?: string) => {
    if (commentId) {
        return `/brands/${brandId}/brainstormDocs/${docId}/comments/${commentId}`
    } else {
        return `/brands/${brandId}/brainstormDocs/${docId}/comments`
    }
}

export const constructTalentRoute = (talentId?: string) => {
    if (talentId) {
        return `/talent/${talentId}`
    }
    return `/talent`
}

export const constructNotificationsRoute = (teamId: string) => {
    return `/notifications/teams/${teamId}`
}

export const constructOfferBySlugRoute = (slug: string) => {
    return `/link/slug/${slug}`
}

export const constructCampaignOfferBySlugRoute = (slug: string) => {
    return `/link/offer/${slug}`
}

export const constructCourseRoute = (courseType: string) => {
    return `course/${courseType}`
}

export const constructBrandLocationsRoute = (brandId: string) => {
    return `/brand-locations/${brandId}`
}

export const constructTutorialsRoute = (tutorialId?: string) => {
    if (tutorialId) {
        return `/tutorials/${tutorialId}`
    } else {
        return '/tutorials'
    }
}

export const constructViewsRoute = (brandId: string, viewId?: string) => {
    if (viewId) {
        return `/brand-views/${brandId}/views/${viewId}`
    } else {
        return `/brand-views/${brandId}/views`
    }
}

export const constructMediaLibraryRoute = (brandId: string, clipId: string) => {
    return `/media-library/${brandId}/mediaLibrary/${clipId}`
}

export const constructMediaLibraryDetailsRoute = (brandId: string) => {
    return `/media-library/${brandId}/details`
}

export const constructShotListsRoute = (brandId: string, shotListId?: string) => {
    if (shotListId) {
        return `shotlists/${brandId}/${shotListId}`
    } else {
        return `shotlists/${brandId}`
    }
}

export const constructTemplatesRoute = (teamId: string) => {
    return `/team-templates/${teamId}`
}

export const constructBrandTagsRoute = (brandId: string) => {
    return `/brand-tags/${brandId}`
}

export const constructShootingSessionsRoute = (userId: string) => {
    return `/shooting-sessions/${userId}/shootingSessions`
}

export const constructBrandPropsRoute = (brandId: string) => {
    return `/brand-props/${brandId}`
}

export const constructUserPermissionsRoute = (teamId: string, uid: string) => {
    return `/permissions/user/${teamId}/${uid}`
}

export const constructBrandPermissionsRoute = (brandId: string, teamId: string) => {
    return `/permissions/brand/${brandId}/${teamId}`
}

export const constructPermissionTemplatesRoute = (teamId: string) => {
    return `/permission-templates/${teamId}`
}

export const constructStoryboardsRoute = () => {
    return `/storyboards`
}

export const constructUsersRoute = (uid?: string) => {
    if (uid) {
        return `/users/${uid}`
    } else {
        return `/users`
    }
}

export const constructDeliverablesRoute = () => {
    return `/deliverables`
}

export const constructTeamsRoute = () => {
    return `/teams`
}

export const constructFrameworkRoute = (teamId?: string, frameworkId?: string) => {
    if (teamId && frameworkId) {
        return `/teams/${teamId}/frameworks/${frameworkId}`
    } else if (frameworkId) {
        return `/frameworks/${frameworkId}`
    } else if (teamId) {
        return `/teams/${teamId}/frameworks`
    } else {
        return `/frameworks`
    }
}

export const constructTalentLibraryRoute = (teamId: string, talentId?: string) => {
    if (talentId) {
        return `/teams/${teamId}/talent-library/${talentId}`
    }
    return `/teams/${teamId}/talent-library`
}

export const constructTalentSuggestionRoute = ({ brandId, talentId }: { brandId?: string; talentId?: string }) => {
    if (brandId && talentId) {
        return `/brands/${brandId}/talentSuggestions/${talentId}`
    }
    if (talentId) {
        return `/talentSuggestions/${talentId}`
    }
    if (brandId) {
        return `/brands/${brandId}/talentSuggestions`
    }
};

export const constructElementRoute = () => {
    return `/element`
}

export const constructGlobalUploadSessionRoute = (brandId: string, creativeId: string) => {
    return `/creative/${brandId}/${creativeId}/global-upload-session`
}